import React, { Component } from 'react';
import Media from 'react-media';
import { Carousel } from 'react-responsive-carousel';

class BlocTxtImage extends Component {
 
    render() {

      

    const slides = this.props.data.map((item,key) => {
     
      if (key === 1 || key === 4){
     
        return (
     

        
          <a href={item.lien} key={item.src350x350} target="_blank" rel="noopener noreferrer" className="BlocTxtImageLien margincenter">
              <div>
                  <img src={item.src350x350} alt={item.titre} />
                  <h2>{item.titre}</h2>
                  <p>{item.texte}</p>
              </div>
          </a>
        
          
          
        );
  

      }else
      {

        return (
     

        
        <a href={item.lien} key={item.src350x350} target="_blank" rel="noopener noreferrer" className="BlocTxtImageLien">
            <div>
                <img src={item.src350x350} alt={item.titre} />
                <h2>{item.titre}</h2>
                <p>{item.texte}</p>
            </div>
        </a>
      
        
        
      );

    }
    }); 

    return (
      <div className="tailledusitecourt1100">
        <h1 className="noborder">PROJETS</h1>

        <Media query="(max-width:705px)">
          {matches =>
            matches ? (
              <Carousel emulateTouch showStatus={false} showIndicators={false} showThumbs={false}  showArrows={true} infiniteLoop={true} autoPlay={true} interval={10000}  className="BlocTxtImage">
          {slides} 
        </Carousel>
            ) : (
              <div className="BlocTxtImage">
                {slides} 
              </div>
            )
          }
        </Media>

        
       
      </div>
    );
  }
}



export default BlocTxtImage;