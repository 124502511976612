import React, { Component } from "react";

class Bas extends Component {
  render() {
    return (
      <div>
        <div className="tailledusite FondationNosObjectifs">
          {/* <h1>{this.props.data.titre}</h1> */}

          <div className="flexcenter">
            <div className="FondationNosObjectifsBloc">
              <img src="/assets/img/valeur1.jpg" alt="Crea" />
              <div className="FondationNosObjectifsBlocTitre">
                {this.props.data.bloc1titre1}
              </div>
              <div className="FondationNosObjectifsBlocTexte">
                {this.props.data.bloc1texte1}
              </div>
            </div>

            <div className="FondationNosObjectifsBloc">
            <img src="/assets/img/valeur2.jpg" alt="Crea" />
              <div className="FondationNosObjectifsBlocTitre">
                {this.props.data.bloc1titre2}
              </div>
              <div className="FondationNosObjectifsBlocTexte">
                {this.props.data.bloc1texte2}
              </div>
            </div>

            <div className="FondationNosObjectifsBloc">
            <img src="/assets/img/valeur3.jpg" alt="Crea" />
              <div className="FondationNosObjectifsBlocTitre">
                {this.props.data.bloc1titre3}
              </div>
              <div className="FondationNosObjectifsBlocTexte">
                {this.props.data.bloc1texte3}
              </div>
            </div>
          </div>
          <div className="clearboth" />
        </div>

        <div className="VotreProjet tailledusite">
          <div>
           
           
            <div className="VotreProjetGaucheDroite">
              <div><h1>{this.props.data.bloc2gauchetitre}</h1>
              <ul>
                <li> <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.bloc2gauchetexte
                }}
              /></li>
              </ul>
              </div>
              <div>
            <h1>{this.props.data.bloc2droitetitre}</h1>
              <ul>
                <li> <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.bloc2droitetexte
                }}
              /></li>
              </ul>
              </div>
            </div>
          </div>
          <div className="clearboth" />
        </div>
      </div>
    );
  }
}

export default Bas;