import React, { Component } from 'react';


class Gauche extends Component {
 
    render() {
 

      
        
        return (
     
   <div className="tailledusitecourt1100"><br/><br/><br/><br/>
   <h1 className="noborder">{this.props.data.Gauchetitre}</h1>
        <div  className="Bloc flexcenter">
          <div className="Bloc50TxtGauche">
            <div className="Bloc50TxtGaucheImg  floatright">
            <img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
              />
              <div className="clearboth" />
            </div>
            <div className="Bloc50TxtGaucheTxt2 Bloc50TxtGaucheTxtVotreProjet">
              {/* <h1 className="flex">{nl2br(this.props.data.Gauchetitre)}</h1> */}
             
              
              <div className="HomePageFull2BlocsChiffres Votreprojettopspace flexspace">
                <div className="content">
                  <div>
                    <img src="/assets/img/euros.png" alt="" />
                  </div>
                  <h2>{this.props.data.GaucheTexte1}</h2>
                </div>

                <div className="content">
                  <div>
                    <img src="/assets/img/pc.png" alt="" />
                  </div>
                  <h2>
                  {this.props.data.GaucheTexte2}
                  </h2>
                </div>

                <div className="content">
                  <div>
                    <img src="/assets/img/entre.png" alt="" />
                  </div>
                  <h2>{this.props.data.GaucheTexte3}</h2>
                </div>
                

              </div>
              <div className="clearboth" />
                <div><div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.Gauchetexte
                }}
              />

                </div><br/>
                
                
             
              {/* <div>{nl2br(this.props.data.Droitetexte)}</div><p>
Pour soumettre votre projet, <a href="/data/Appel_a_projets_sept_revu_def.pdf" className="linkrouge">cliquez ici</a>. Il vous sera demandé de créer un compte. Cet espace vous sera dédié. Il vous permettra de compléter chaque section à votre rythme, jusqu’à la fin du délai de l’appel.
Le comité de sélection, composé des salariés de plusieurs entités du groupe Iliad se réunira pour sélectionner les projets qui seront soutenus.</p>
<p>Pour télécharger l’ensemble des éléments relatifs à l’appel à projets, <a href="/data/Appel_a_projets_sept_revu_def.pdf" className="linkrouge" download>cliquez ici</a></p> */}
              <div className="clearboth" />

            </div>
          </div><div className="clearboth" />
        </div><div className="clearboth" />

        <img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
                className="mobile"    />

              <div className="textleft">
              
            <h1 className="noborder">Votre projet</h1>
            Aucun appel à projets n’est actuellement en cours. Nous vous invitons à nous suivre sur nos <a href="https://www.linkedin.com/company/28176024/admin/" className="linkrouge">réseaux sociaux</a> pour être au courant des prochaines actualités de la Fondation d’entreprise Free. »

              {/* <h1 className="noborder">L’intelligence collective dans les territoires au service d’un numérique plus inclusif</h1>

<p>Date limite de dépôt des candidatures : <strong>28 octobre 2021 à minuit</strong></p>

<p>13 millions de Français sont « éloignés du numérique », souvent par défaut de compétences dans l’utilisation des outils numériques. Le gouvernement français, conscient de ces enjeux, mobilise 250 millions d’euros dans le cadre du plan France Relance pour démultiplier et accélérer les actions d’accompagnement à l’inclusion numérique sur tous les territoires.</p>

<p>Dans ce cadre, la Fondation Free souhaite placer ce nouvel appel à projets sous l’angle de la cohésion et du partage et agir plus efficacement à l’échelle des territoires en faveur de l’inclusion numérique. La Fondation souhaite apporter son soutien aux acteurs locaux engagés sur ce sujet. Nous souhaitons soutenir des initiatives portées par plusieurs structures (minimum deux), qu’elles soient associatives ou publiques (collectivités territoriales, mairies, centres sociaux, bibliothèques, médiathèques, etc.), et qui veulent agir sur leur territoire en faveur des habitants dans le but de rendre le numérique accessible au plus grand nombre. Notre soutien sera accordé aux réseaux de proximité qui proposent des activités numériques, développent de nouveaux lieux de médiation numérique sur leur territoire, améliorent ceux existants ou mettent en œuvre d’autres actions d’inclusion numérique au service des habitants.
Les bénéficiaires des projets déposés doivent être les habitants des territoires en question.</p>

<h2>CONDITIONS D’ELIGIBILITE :</h2>

<p>La Fondation s’adresse donc aux organismes sans but lucratif et d’intérêt général agissant en faveur ou ayant un projet en rapport avec la thématique « L’intelligence collective dans les territoires au service d’un numérique plus inclusif ». Les projets déposés devront être portés par au minimum deux structures d’intérêt général (association et/ou organisme public), mais une seule de ces structures déposera le dossier. Les noms des autres structures devront apparaître dans le dossier. La finalité du projet déposé devra être l’accessibilité du numérique aux habitants du territoire d’implantation. La structure devra être enregistrée sur le territoire Français et avoir au minimum un an d’existence. Le projet devra se dérouler en France.</p> 
<p>Pour candidater, il conviendra d’être en mesure de présenter un projet clair, avec des besoins précis.</p>

<h2>MODALITES DE SOUTIEN FINANCIER :</h2>

<p>
<ul>
<li> L’enveloppe maximum par projet est de 20 000€.</li>
<li> Le budget détaillé de votre projet et de vos besoins concernant votre sollicitation devra nous être fourni pour l’étude de votre dossier. Si votre projet venait à être sélectionné, nous vous demanderons des retours concrets et quantifiables sur l’utilisation de notre soutien. Les projets déjà soutenus et financés par plusieurs structures seront privilégiés. </li>
<li> Les pièces jointes à fournir seront différentes en fonction du statut de votre structure. Vous pouvez vous référer au règlement de l’appel à projets téléchargeable ci-dessous.</li>
<li> Le montant demandé à la Fondation Free ne doit pas dépasser un tiers du budget global du projet.</li>
</ul>
</p>


<p>
La Fondation Free n’étudiera pas les dossiers concernant :
  <ul><li>Des projets individuels,</li> 
  <li>Des projets sans rapport avec le thème énoncé,</li>
  <li>Des voyages scolaires.</li></ul>
</p>

<p>Pour soumettre votre projet, <a href="https://projets.fondation-free.fr/fr/"><b>cliquez ici</b></a>. Il vous sera demandé de créer un compte. Cet espace vous sera dédié. Il vous permettra de compléter chaque section à votre rythme, jusqu’à la fin du délai de l’appel.<br/><br/>Le comité de sélection, composé des salariés de plusieurs entités du groupe Iliad, se réunira pour sélectionner les projets qui seront soutenus.</p>
<p>Pour télécharger l’ensemble des éléments relatifs à l’appel à projets, <a href="/data/Reglement_AAP_sept21_FF.pdf"><b>cliquez ici</b></a>.</p> */}
          </div>     
      </div>
      
    
    );
  }
}

export default  Gauche;