import React from 'react';
import {  NavLink } from "react-router-dom";
import BlocImageIntro from '../../BlocImageIntro.js';
import { Helmet } from "react-helmet";

class page404 extends React.Component{

  
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
   
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render(){


    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
      
        <div>
          
          <Helmet>
            <title>Erreur 404 Page introuvable | Fondation Free</title>
            <meta name="description" content="Page Introuvable !" />
          </Helmet>
          
          <BlocImageIntro data={databis.Perdu.BlocImageIntro} />
          
          <div className="sitewidth mentions plandusite">
          <h1>Erreur 404 Page introuvable !</h1>

          <div><NavLink to="/Fondation">
            Fondation
          </NavLink></div>

          <div className="nomouselink bold">
            Actions
          </div>
      
          <div><NavLink to="/Culture" >
          Culture
          </NavLink></div>
         
          <div><NavLink to="/Partage" >
          Partage
          </NavLink></div>
        
          <div><NavLink to="/Projets" >
          Projets soutenus
          </NavLink></div>
       

          <div><NavLink to="/Votreprojet" >
      Votre projet
      </NavLink></div>
      
      <div><NavLink to="/Collaborateurs" >
      Collaborateurs
      </NavLink></div>
      </div>

        </div>
      );
    }

 
      }
  }
  export default page404 ;