import React from "react";
import Bas from "./Bas.js";
import BlocImageIntro from "../../BlocImageIntro.js";
import Droite from "../../Droite.js";
import { Helmet } from "react-helmet";


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div />;
    } else {
      return (
        <div>

        <Helmet>
            <title>Fondation Free</title>
            <meta name="description" content="La Fondation Free a été créée en 2006 afin de contribuer à la lutte contre la fracture numérique. Elle agit dans le prolongement 
de l'entreprise Free en permettant l'accès de chacun à la technologie" />
          </Helmet>

          <BlocImageIntro data={databis.Fondation.BlocImageIntro} />
          <Droite data={databis.Fondation.ImageGaucheTextedroite} />
          <Bas data={databis.Fondation.Bas} />
        </div>
      );
    }
  }
}
export default Home;
