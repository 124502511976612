import React, { Component } from 'react';
import { Helmet } from "react-helmet";



class Actualites extends Component {
 

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
   
    };
  }

  

  componentDidMount() {

  
    fetch("/data/article/actualites.json")
      .then(res => res.json())
      .then(
        (result) => {
           
          this.setState({
            isLoaded: true,
            article: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

     
  }



    render() {
     
 

      const { error, isLoaded, article } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="articleMinHeight"></div>;
    } else {
      return (
      <div>
       <Helmet>
            <title>Actualités  | Fondation Free</title>
            <meta name="description" content="" />
          </Helmet>

        <div className="BlocImageIntro" style={{backgroundImage:"url(/data/img/Fondation-BlocImageIntro-src2880x982.jpg)"}}></div>

      <div className="tailledusitecourt1100 Article articleMinHeight">
        <div dangerouslySetInnerHTML={{__html: article.article.content}} className="actualites"/>
       <div class="breaker"></div>
       </div>
       </div>
    );
      }
  }
}



export default Actualites;