import React from "react";
import { elastic as Menu } from "react-burger-menu";
import {  NavLink } from "react-router-dom";

class props extends React.Component{

  constructor (props)   {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }

  toggleMenu () {
    this.setState({menuOpen: !this.state.menuOpen})
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
   
  }

render (){
  return (
    // Pass on our props
    <Menu {...props} isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}>


      <NavLink className="menu-item" to="/Fondation"  activeClassName="menu-item-active" onClick={() => this.toggleMenu()}>
        Fondation
      </NavLink><div className="menu-itemborder"></div>

      <div className="menu-item nomouselink bold">
        Actions
      </div>
      
          <NavLink className="menu-item" to="/Culture"  activeClassName="menu-item-active"  onClick={() => this.toggleMenu()}>
          Culture
          </NavLink>
         
          <NavLink className="menu-item" to="/Partage"  activeClassName="menu-item-active"  onClick={() => this.toggleMenu()}>
          Partage
          </NavLink>
        
         <div className="menu-itemborder"></div>
       

      <NavLink className="menu-item" to="/Votreprojet"  activeClassName="menu-item-active"  onClick={() => this.toggleMenu()}>
      Votre projet
      </NavLink>
      {/* <NavLink className="menu-item" to="/Collaborateurs"  activeClassName="menu-item-active"  onClick={() => this.toggleMenu()}>
      Espace Collaborateurs
      </NavLink>
  */}
      
    </Menu>
  );
}
};
export default props ;