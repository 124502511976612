import React, { Component } from "react";
import nl2br from "react-nl2br";

class Droite extends Component {
  render() {
    return (
      <div className="tailledusitecourt1100">
      
        <div  className="Bloc flexcenter">
          <div className="Bloc50TxtGauche">
            <div className="Bloc50TxtGaucheImg">
              <img
                src={this.props.data.Droitesrc707x713}
                alt={this.props.data.Droitetitre}
              />
              <div className="clearboth" />
            </div>
            <div className="Bloc50TxtGaucheTxt">
              <h1 className="flex">{nl2br(this.props.data.Droitetitre)}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.Droitetexte
                }}
              />
             
              <div className="clearboth" />
            </div>
          </div><div className="clearboth" />
        </div><div className="clearboth" />
      </div>
    );
  }
}

export default Droite;
