import React, { Component } from "react";
import Slide from "react-reveal/Slide";
import { Link } from 'react-router-dom';



class Content extends Component {

render() {
  


const divStyle3 = {
  backgroundImage: 'url(' + this.props.data.Bassrc2000x1332 + ')',
};


    return (
      <div>

        <Slide bottom>
        <h1 className="noborder margintop">VOTRE PROJET</h1>
        <Link to={this.props.data.BasLien} >
          <div className="HomePageFullFoot" style={divStyle3}>
            <div className="content">
                <h1>{this.props.data.BasTitre}</h1>
                <h3>{this.props.data.BasTitre2}</h3>
                <h2>{this.props.data.BasTexte}</h2>
            </div>
          </div>
         </Link>
         
        </Slide>
        <div className="clearboth" />
      </div>
    );
  }
}

export default Content;