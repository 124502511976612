import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class Article extends Component {
 

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
   
    };
  }

  

  componentDidMount() {

    var url = window.location.pathname;
    url = url.replace(".html",".json");

   
  
    fetch("/data/"+url)
      .then(res => res.json())
      .then(
        (result) => {
           
          this.setState({
            isLoaded: true,
            article: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

     
  }



    render() {
     
      var titre = '';
      var desc = '';

      if( window.location.pathname.includes("covid19")){
          titre = 'Soutient à des projets de visières impression 3D - COVID19';
          desc = 'Chez Free, plusieurs collaborateurs et collaboratrices ont ainsi décidé de mettre leurs compétences de maker au service du bien commun. “Il y a eu de nombreuses initiatives solidaires de salariés depuis quelques mois, précise Célia Mousset. La direction et la Fondation ont décidé de les remercier en fournissant du matériel à leur groupe de makers et à d’autres groupes qui en avaient besoin.” ';
      }else if(window.location.pathname.includes("phones")){
          titre = 'Un don de 3 000 téléphones pour les Ateliers du Bocage';
          desc = 'La Fondation Free a collaboré avec les Ateliers du Bocage dans le cadre d’un don de plus de 3 000 téléphones qui seront reconditionnés ou recyclés.';
      }else if(window.location.pathname.includes("projets")){
        titre = 'De belles initiatives pour le 1er appel à projets 2020 de la Fondation';
        desc = 'Début 2020, la Fondation Free a lancé un appel à projet sur le thème « Santé et handicap : des solutions par le numérique ». 85 projets ont été reçus, 24 présentés au comité de sélection, composé de salariés, et 7 lauréats ont été retenus pour être soutenus financièrement par la Fondation, avec des montants compris entre 7000€ et 20 000€. Voici les projets retenus.';
    }
     


      const { error, isLoaded, article } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="articleMinHeight"></div>;
    } else {
      return (
      <div>
       <Helmet>
            <title>{titre}  | Fondation Free</title>
            <meta name="description" content={desc} />
          </Helmet>

        <div className="BlocImageIntro" style={{backgroundImage:"url(/data/img/Fondation-BlocImageIntro-src2880x982.jpg)"}}></div>

      <div className="tailledusitecourt1100 Article articleMinHeight">
        <div dangerouslySetInnerHTML={{__html: article.article.content}} />
       </div>
       </div>
    );
      }
  }
}



export default Article;