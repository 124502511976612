import React from 'react';
import Content from './phpform.js';
import BlocImageIntro from '../../BlocImageIntro.js';
import { Helmet } from "react-helmet";


class Contacts extends React.Component{

  
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
   
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render(){


    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Erreur: {error.message}</div>;
    } else if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div className="formulaire">
          <Helmet>
            <title>Appel à projets collaborateurs | Fondation Free</title>
            <meta name="description" content="Contactez-nous - la Fondation Free s'engage aux côtés des acteurs associatifs pour favoriser la connaissance et la montée en compétences numériques" />
          </Helmet>
          <BlocImageIntro data={databis.Formulaire.BlocImageIntro} />
            <Content data={databis.Contacts.Bas}/>
        </div>
      );
    }

 
      }
  }
  export default Contacts ;