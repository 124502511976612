import React from 'react';
import Content from './Content.js';
import BlocImageIntro from '../../BlocImageIntro.js';
import { Helmet } from "react-helmet";

class Mentions extends React.Component{

  
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
   
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render(){


    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Erreur: {error.message}</div>;
    } else if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div>

        <Helmet>
            <title>Mentions Légales | Fondation Free</title>
            <meta name="description" content="Mentions Légales - La fondation encourage la transmission d'un savoir numérique, celui du dialogue et de l'échange, de la collaboration et de la création. La Fondation s'engage ainsi auprès d'acteurs qui placent la culture et le partage au cœur de leur projet" />
          </Helmet>

            <BlocImageIntro data={databis.Mentions.BlocImageIntro} />
            <Content data={databis.Mentions.Bas}/>
        </div>
      );
    }

 
      }
  }
  export default Mentions ;