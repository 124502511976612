import React, { Component } from 'react';
import nl2br from 'react-nl2br';

class Gauche extends Component {
 
    render() {
 

          let Chiffre1
         if (this.props.data.Chiffre1 !== '') {
          Chiffre1 = <div  className="content">
          <h1>{this.props.data.Chiffre1}</h1>
          <h2>{this.props.data.ChiffreTxt1}</h2>
        </div>

         } else {
          Chiffre1 = null
         }

         let Chiffre2
         if (this.props.data.Chiffre2 !== '') {
          Chiffre2 = <div  className="content">
          <h1>{this.props.data.Chiffre2}</h1>
          <h2>{this.props.data.ChiffreTxt2}</h2>
        </div>

         } else {
          Chiffre2 = null
         }

         let Chiffre3
         if (this.props.data.Chiffre3 !== '') {
          Chiffre1 = <div  className="content">
          <h1>{this.props.data.Chiffre3}</h1>
          <h2>{this.props.data.ChiffreTxt3}</h2>
        </div>

         } else {
          Chiffre3 = null
         }


        
        return (
     
   <div className="tailledusitecourt1100">
    
   {/* <h1 className="noborder">EN PLUS</h1> */}
        <div  className="Bloc flexcenter">
          <div className="Bloc50TxtGauche">
            <div className="Bloc50TxtGaucheImg  floatright">
              <img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
              />
              <div className="clearboth" />
            </div>
            <div className="Bloc50TxtGaucheTxt2">
              <div className="Bloc50TxtGaucheTxt2Culture">
              <h1 className="flex">{nl2br(this.props.data.Gauchetitre)}</h1>
              <div>{nl2br(this.props.data.Gauchetexte)}</div>
              <div className="clearboth" />

              </div>
              <div className="HomePageFull2BlocsChiffres topspace flexspace">
                {Chiffre1}
                {Chiffre2}
                {Chiffre3}
              </div>


            </div><div className="clearboth" />
          </div><div className="clearboth" />
         
        </div><img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
             className="mobile" />
      </div>
      
    
    );
  }
}

export default  Gauche;