import React, { Component } from 'react';


class Content extends Component {
 
  

  render() {

        return (
            <div className="tailledusite mentions">
                
{/* <p>Vous êtes actuellement connecté au site <a href="https://fondation-free.fr">https://fondation-free.fr</a> édité par la Fondation d’entreprise Free</p>

<p>Siège social : <strong>8, rue de la Ville l’Évêque, 75008 Paris – France</strong></p>

<p>Hébergement : Fondation d’entreprise Free</p>

<p>Siège social hébergeur : <strong>8 rue de la Ville l’Évêque , 75008 Paris – France</strong></p>

<p>L’éditeur s’engage à respecter l’ensemble des lois concernant la mise en place et l’activité d’un site internet.</p>

<p>Nous vous rappelons que le secret des correspondances n’est pas garanti sur le réseau internet et qu’il appartient à chaque utilisateur d’internet de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination d’éventuels virus circulant sur le réseau internet.</p>

<p>Vous disposez d’un droit d’accès, de rectification et d’opposition des données vous concernant dans les conditions prévues par la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés. Pour cela, il suffit de nous en faire la demande en ligne ou par courrier en nous indiquant vos nom, prénom et adresse par e-mail à l’adresse suivante : <a href="mailto:contact@fondation-free.fr">contact@fondation-free.fr</a></p>


 */}
 <p>Vous êtes actuellement connecté au site <a href="https://fondation-free.fr">https://fondation-free.fr</a> édité par la Fondation d’entreprise
Free.</p>
<p>
<b>Editeur</b><br/>
Fondation d’entreprise Free<br/>
Siège social : 8, rue de la Ville l’Évêque, 75008 Paris – France<br/>
N° d’autorisation : 531 662 427<br/>
Courrier électronique : <a href="mailto:contact@fondation-free.fr">contact@fondation-free.fr</a><br/>
Directeur de la publication : Jean-Claude MICHOT
</p>

<p>
<b>Hébergeur</b><br/>
Fondation d’entreprise Free<br/>
Siège social : 8 rue de la Ville l’Évêque, 75008 Paris – France<br/>
Courrier électronique : <a href="mailto:contact@fondation-free.fr">contact@fondation-free.fr</a>
</p>

<p><b>Propriété intellectuelle</b><br/>
Le présent site internet ainsi que l’ensemble de ses contenus (notamment
les données, informations, photos, logos et marques) sont la propriété
exclusive de la Fondation d’entreprise Free ou de ses partenaires. Toute
reproduction, représentation, traduction, adaptation ou citation, intégrale
ou partielle, quel qu'en soit le procédé ou le support, est strictement
interdite en dehors des cas prévus par la loi ou expressément autorisés par
leur propriétaire.
</p>
        <div className="clearboth"></div><br/> 
        
    </div>


            )

        }
}

export default Content;