import React, { Component } from "react";
import Slide from "react-reveal/Slide";
import { Link } from 'react-router-dom';

class Content extends Component {

render() {
  
  const divStyle1 = {
    backgroundImage: 'url(' + this.props.data.Gauchesrc1200x1200 + ')',
};

const divStyle2 = {
  backgroundImage: 'url(' + this.props.data.Droitesrc1200x1200 + ')',
};




    return (
      <div>

     <h1 className="noborder">NOS ACTIONS</h1>
        <Slide bottom>
        <div className="HomePageFull2Blocs">
        <Link to={this.props.data.GaucheLien} className="HomePageFull2Blocs50" style={divStyle1}>
           
              <div className="content">
                <h1>{this.props.data.GaucheTitre}</h1>
                <h4>Explorer les mondes numériques</h4>
                <h2>{this.props.data.GaucheTexte}</h2>
              </div>
           
        </Link>
       
            <Link to={this.props.data.DroiteLien} className="HomePageFull2Blocs50" style={divStyle2}>
              <div className="content">
                <h1>{this.props.data.DroiteTitre}</h1>
                <h4>Rassemblés pour innover</h4>
                <h2>{this.props.data.DroiteTexte}</h2>
              </div>
            </Link>
        
          <div className="clearboth" />
        </div>
        </Slide>  
       
      
        <div className="clearboth" />
      </div>
    );
  }
}

export default Content;