import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Article extends Component {
 

  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
   
    };
  }


  componentDidMount() {


      fetch("./data/article/accroche_home.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            article: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

  }

    render() {

        

      const { error, isLoaded, article } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div></div>;
    } else {

      const titre1 = article.accroche1file.replace(".html","") ;
      const titre2 = article.accroche2file.replace(".html","") ;
      const titre3 = article.accroche3file.replace(".html","") ;


      return (
      <div className="tailledusitecourt1100 HomePageActu">
        <h1 className="noborder">ACTUALITES</h1>

    

        <div className="BlocTxtImage article">
          <Link to={{pathname:'article/'+article.accroche1file}} ><div className="BlocTxtImageLien margincenter"><img src={'/data/article/'+titre1+'/'+titre1+'.jpg'} alt={article.accroche1.replace(".html","")}/><h2>{article.accroche1.replace(".html","")}</h2></div></Link>
          <Link to={{pathname:'article/'+article.accroche2file}} ><div className="BlocTxtImageLien margincenter"><img src={'/data/article/'+titre2+'/'+titre2+'.jpg'}  alt={article.accroche2.replace(".html","")} /><h2>{article.accroche2.replace(".html","")}</h2></div></Link>
          <Link to={{pathname:'article/'+article.accroche3file}} ><div className="BlocTxtImageLien margincenter"><img src={'/data/article/'+titre3+'/'+titre3+'.jpg'}  alt={article.accroche3.replace(".html","")} /><h2>{article.accroche3.replace(".html","")}</h2></div></Link>

         
        </div>

        <h1 className="noborder">RAPPORT D'ACTIVITÉS</h1>
        <div className="HomePage BlocImageIntroTitre2"  style={{marginTop: "-40px"}}>        < a href="/data/Rapport-activites-2020-fondation-Free.pdf" download alt="Télécharger le rapport d'activités 2020 de la Fondation">Télécharger le rapport d'activités 2020 de la Fondation</a><br/>< a href="/data/Rapport-activites-2019.pdf" download alt="Télécharger le rapport d'activités 2019 de la Fondation">Télécharger le rapport d'activités 2019 de la Fondation</a>
<br/>        
        </div>

      </div>
   
    );
      }
  }
}



export default Article;