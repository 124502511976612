import React from 'react'
import { NavLink } from 'react-router-dom'
//import { NavLink } from 'react-router-dom'

const Header = ({ children }) => (
   <div>
    
    <div className="HomePageFullFooter">
        {/* <div className="HomePageFullFootBg"></div> */}

   
   <div className="footerfree" >
               
                <div className="footerliens">
                    <div>
                        
                        <NavLink to="/Contacts" title="La Fondation" className="nav-link">Contact</NavLink>
                        <NavLink to="/Mentions" title="La Fondation" className="nav-link">Mentions Légales</NavLink>
                        
                    </div>
                    
                </div>

                <div className="reseauxsociaux flexcenter">
                    
                    <a href="https://www.facebook.com/FondationFree/" title=" Fondation d’entreprise Free Facebook" className="nav-link" target="_blank" rel="noopener noreferrer"><img src="/assets/img/facebook.png" alt="Le facebook de la Fondation" /></a>
                    <a href="https://twitter.com/FondationFree" title=" Fondation d’entreprise Free Twitter" className="nav-link" target="_blank" rel="noopener noreferrer"><img src="/assets/img/twitter.png" alt="Le twitter de la Fondation" /></a>
                    
            </div>

                <div className="footerliens">© 2019 -  Fondation d’entreprise Free</div>
            </div>
            </div>
            </div>
)

export default Header