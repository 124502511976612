import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <section>
    <div id="page-wrap">
    <Header />
    {children}
    <Footer />
    </div>
  </section>
)

export default Layout