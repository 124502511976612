import React, { Component } from 'react';
//import Fade from 'react-reveal/Fade';




class Video extends Component {

  

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      active: false,
    };
  }
  


  
  
  handleClick() {
      const currentState = this.state.active;
      this.setState({ active: !currentState });
  }

    render() {


    

      return (
   <div className="tailledusitecourt1100 bloccenter"> <h1 className="noborder">T&Eacute;MOIGNAGES</h1> 
    <video src="/data/konexio_free_fondation.mp4" controls></video>
  </div>

    );
  }
}



export default Video;