import React, { Component } from 'react';


class Gauche extends Component {

    render() {

    return (
        <div>

            <div className="tailledusitecourt1100 Collaborateurs">
                <h1 className="marginbottom">COLLABORATEURS ILIAD</h1>
                <div className="BlocImageIntroTitre2">Vous êtes impliqués dans une association ou vous connaissez une association que vous souhaitez aider ? Devenez parrain, proposez votre projet et mobilisez vos collègues pour tenter d'obtenir un financement de la Fondation Free</div>

                <br/><br/>
                <h1 className="noborder marginbottom">MODALITÉS</h1>
                <div className="HomePageFull2BlocsChiffres Votreprojettopspace flexspace modalitesbloc">
                    <div className="content">
                        <div>
                        POUR ÊTRE PARRAIN
                        </div>
                        <ul>
                            <li>Être un collaborateur Iliad avec minimum un an d’ancienneté dans le groupe</li>
                            <li>Compléter la fiche de parrainage & mobiliser l’association pour qu’elle complète son dossier</li>
                            <li>S’engager à suivre le projet sur la durée du partenariat

                            </li>
                        </ul>
                    </div>

                    <div className="content">
                        <div>
                        POUR ÊTRE ÉLIGIBLE
                        </div>
                        <ul>
                            <li>Le projet doit être porté par une association française (même si l’action peut se dérouler à l’étranger)</li>
                            <li>L’association doit avoir minimum 1 an d’existence </li>
                            <li>Seules sont concernées des actions liées au numérique</li>
                           

                        </ul>
                    </div>

                    {/* <div className="content">
                        <div>
                        NE POURRONT FAIRE L’OBJET D’UN SOUTIEN
                        </div>
                        <ul>
                            <li>Les projets individuels ou sans vocation sociale</li>
                            <li>Les projets portés par des structures étrangères</li>
                            <li>Les projets sans lien avec le numérique</li>
                        </ul>
                    </div> */}



                </div>
                <div className="clearboth"></div>
                <p><b>A noter : </b>Les projets individuels, portés par une association étrangère ou sans lien avec le numérique ne pourront faire l’objet d’un soutien de la Fondation.</p>
                <br/>
                </div>
                <div className="HpBlocGris">
                <div className="tailledusitecourt1100"><br/><br/>
                <h1 className="noborder marginbottom">PROCÉDURE</h1>
                <h5 className="textcenter  marginbottom">SOUMISSION ET SÉLECTION DES PROJETS</h5>
                <div className="HomePageFull2BlocsChiffres Votreprojettopspace flexspace modalites">
                    <div className="content">
                        <h1>1</h1>
                        <h2>PARLEZ-NOUS <br/>DE VOTRE PROJET</h2>
                        <h6>En complétant la fiche de parrainage</h6>
                        <ul>
                            <li>Sur cet espace, remplissez les champs demandés, ces premiers éléments permettront à la Fondation de s’assurer que votre projet répond bien aux critères demandés.<br/><br/></li>
                        </ul>
                        <p><strong>DATE LIMITE : 31 MAI 2019</strong></p>
                    </div>

                    <div className="content">
                    <h1>2</h1>
                    <h2>INVITEZ L’ASSOCIATION <br/>A COMPLÉTER LE DOSSIER</h2>
                    <h6>Via un lien qui vous sera transmis par mail</h6>
                        <ul>
                            <li>Si votre dossier est validé, vous recevrez un mail confirmant que vous pouvez passer à l’étape suivante. Ce mail comprendra un lien que vous pourrez transmettre à l’association afin qu’elle complète le dossier sur la plateforme prévue à cet effet.</li>
                        </ul>
                        <p><strong>DATE LIMITE : 30 JUIN 2019</strong></p>
                    </div>

                    <div className="content">
                    <h1>3</h1>
                    <h2>PRÉ-SÉLECTION DES DOSSIERS<br/>Par la Fondation</h2>
                    <h6>&nbsp;</h6>
                        <ul>
                            <li>Une fois l’appel à projets terminé, les dossiers complets seront à nouveau examinés au vu des éléments fournis par l’association. Si votre dossier fait partie des projets retenus vous en serez informé par mail. Il restera une étape ultime à passer, celle du vote des collaborateurs.</li>
                        </ul>
                        <p><strong>DÉBUT JUILLET 2019</strong></p>
                    </div>

                
                    
                   
             
                </div> 
                <br/><br/>
                <h5 className="textcenter  marginbottom">VOTES DES COLLABORATEURS</h5>
                <p>Les projets retenus seront proposés cet été sur une plateforme de vote<br/> accessible uniquement par les collaborateurs Iliad France<br/><br/> Une communication sera faite en interne dès que les votes seront ouverts ! </p>

                <div className="HomePageFull2BlocsChiffres Votreprojettopspace flexspace modalitesbloc">
                    <div className="content">
                        <div>
                        PARRAINS, <br/>MOBILISEZ VOS COLLÈGUES !
                        </div>
                        <ul>
                            <li>A vous de jouer pour communiquer sur votre projet et convaincre vos collègues pour obtenir le maximum de votes !</li>
                        </ul>
                    </div>

                    <div className="content">
                        <div>
                        COLLABORATEURS ILIAD,  <br/>VOTEZ POUR VOS PROJETS PRÉFÉRÉS !
                        </div>
                        <ul>
                            <li>Chaque collaborateur dispose de 3 votes qu’il pourra attribuer librement : les 3 votes au même projet, à 2 ou à 3 projets différents, c’est vous qui décidez !</li>
                        </ul>
                    </div>

                 

                </div>

                <h5 className="textcenter  marginbottom">ANNONCE DES RÉSULTATS</h5>
                <p>Les 3 projets qui emportent le + de votes seront soutenus par la Fondation.</p>
                {/* <a href="/Collaborateurs/Formulaire" className="buttonred center fontsizebig">Je complète la fiche de parrainage</a> */}
                <br/><br/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Gauche;