import React from "react";
// import  GoogleAnalytics from './GA.js';
import { withRouter, Switch, Route , Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "./Layout";
import Fondation from "./page/Fondation/Fondation";
import Partage from "./page/Partage/Partage";
import Actualites from "./page/Actualites/Actualites";
import Collaborateurs from "./page/Collaborateurs/Collaborateurs";
import Formulaire from "./page/Formulaire/Formulaire";
import Votreprojet from "./page/Votre-projet/Votre-projet";
import NotFoundPage from "./page/404/404";
import Mentions from "./page/Mentions/Mentions";
import Contacts from "./page/Contacts/Contacts";
import HomePage from "./page/HomePage/HomePage";
import Culture from "./page/Culture/Culture";
import Article from "./page/Article/Article"; 

  
 
const App = ({ location }) => {
  const currentKey = location.pathname.split("/")[2] || location.pathname.split("/")[1] || "/";
  console.log(currentKey);
  const timeout = { enter: 800, exit: 1800 };
 
 
  return (
    <Layout>
      <TransitionGroup component="main" className="page-main">
        <CSSTransition
          key={currentKey} 
          timeout={timeout}
          classNames="fade"
          appear= {true}
          exit={false}
          onEnter = {() => {
              window.scrollTo(0, 0)
          }}
        >
          <section className="page-main-inner" >
            <Switch > 
              {/* location={location}  <Route path="/" exact component={HomePage} /> */}
              <Route path="/"  exact component={HomePage}/>  
              <Route path="/Fondation"  exact component={Fondation} /> 
              <Route path="/Culture" exact component={Culture} />
              <Route path="/Actualites" exact component={Actualites} />
              <Route path="/Partage" exact component={Partage} />
              <Route path="/Votreprojet" exact component={Votreprojet} />
              <Route path="/Collaborateurs"  exact component={Collaborateurs}/>
              <Route path="/Collaborateurs/Formulaire"  exact component={Formulaire}/>
              <Route path="/Article"  component={Article}/>
              
              <Route path="/Mentions"  exact component={Mentions}/>  
              <Route path="/Contacts"  exact component={Contacts}/>
              
              
              <Route path="/404" component={NotFoundPage} />
              <Redirect from='*' to='/404' />
            </Switch>
            {/* <GoogleAnalytics /> */}
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};

export default withRouter(App);
