import React, { Component } from 'react';
import nl2br from 'react-nl2br';

class Gauche extends Component {
 
    render() {
 

        
        return (
     
   <div className="tailledusitecourt1100">
   {/* <h1 className="noborder">EN +</h1> */}
        <div  className="Bloc flexcenter">
          <div className="Bloc50TxtGauche">
            <div className="Bloc50TxtGaucheImg  floatright">
              <img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
              />
              <div className="clearboth" />
            </div>
            <div className="Bloc50TxtGaucheTxt2">
              <h1 className="flex">{nl2br(this.props.data.Gauchetitre)}</h1>
              <div>{nl2br(this.props.data.Gauchetexte)}</div>
              <div className="clearboth" />


              <div className="HomePageFull2BlocsChiffres flexspace">

              <div  className="content">
                <h1><a href="https://www.openstreetmap.org" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logolibre1.jpg" alt="Logo"/></a></h1>
                
              </div>

              <div  className="content">
                <h1><a href="http://www.cemea.asso.fr/" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logolibre2.jpg" alt="Logo"/></a></h1>
              </div>

              <div  className="content">
                <h1><a href="https://fr.openfoodfacts.org/" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logolibre3.jpg" alt="Logo"/></a></h1>
              </div>

              <div  className="content">
                <h1><a href="https://ubuntu-fr.org/" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logolibre4.jpg" alt="Logo"/></a></h1>
              </div>

              <div  className="content">
                <h1><a href="http://videolan.org" target="_blank" rel="noopener noreferrer"><img src="/assets/img/logolibre5.jpg" alt="Logo"/></a></h1>
              </div>



              </div>


            </div>
          </div><div className="clearboth" />
        </div><div className="clearboth" />
        <img
                src={this.props.data.Gauchesrc707x713}
                alt={this.props.data.Gauchetitre}
             className="mobile" />
      </div>
      
    
    );
  }
}

export default  Gauche;