import React from 'react';
import Content from './Content.js';
import BlocImageIntro from '../../BlocImageIntro.js';
//import BlocTxtImage from "./BlocTxtImage.js";
import Article from "./Article.js";
import Bottom from "./Bottom.js";
import { Helmet } from "react-helmet";
// import {  NavLink } from "react-router-dom";

class Home extends React.Component{

  
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      article:{},
      error: null,
      isLoaded: false
   
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

 

  }

  render(){


    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div className="HomePage">

        <Helmet>
            <title>Fondation Free</title>
            <meta name="description" content="La fondation encourage la transmission d'un savoir numérique, celui du dialogue et de l'échange, de la collaboration et de la création. La Fondation s'engage ainsi auprès d'acteurs qui placent la culture et le partage au cœur de leur projet" />
           
          </Helmet>

          <div className="HomePageBlocIntro">
            <BlocImageIntro data={databis.HomePage.BlocImageIntro}  />
            <div className="BlocImageIntroTitre2">
            <a href="https://live-event.io/fondation-free/index.php" className="lienrouge fontsizebig2">Retrouvez ici le replay de la remise des prix de l'appel à projets<br/> "L'accès à l'éducation par le numérique"</a>
          </div>

          </div>


          
        <div className="tailledusitecourt hpspace">
        <Content data={databis.HomePage.Bas}/>
        </div>
        <div className="HpBlocGris">
        <Article  />
        </div>
        <div className="tailledusitecourt hpspace">
        <Bottom data={databis.HomePage.Bas}/>
        </div>
        </div>
      );
    }

 
      }
  }
  export default Home ;
 