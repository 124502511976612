import React from 'react';
import BlocImageIntro from '../../BlocImageIntro.js';
import Bloc1 from './bloc1.js';
import Bloc3 from './bloc3.js';
import { Helmet } from "react-helmet";


class VotreProjet extends React.Component{


  
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
   
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        (result) => {
          
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )

     

  }

  render(){


    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Erreur: {error.message}</div>;
    } else if (!isLoaded) {
      return <div></div>;
    } else {
      return (
        <div className="VotreProjetPage">

        <Helmet>
            <title>Soumettre votre projet | Fondation Free</title>
            <meta name="description" content="La Fondation Free soutient une trentaine de projets par an. Si vous aussi vous souhaitez nous proposer votre projet vous pourrez le faire dès l’ouverture d’un appel à projets" />
          </Helmet>

         
        <BlocImageIntro data={databis.Votreprojet.BlocImageIntro} />
        <Bloc1 data={databis.Votreprojet.Gauchedroite} />
        <Bloc3 data={databis.Votreprojet.ImageDroiteTexteGauche} />
         
  
        </div>
      );
    }

 
      }
  }
  export default VotreProjet ;