import React from "react";

import BlocImageIntro from "../../BlocImageIntro.js";
import Gauche from "./Gauche.js";
import Video from './Video.js';
import Droite from "./Droite.js";
import { Helmet } from "react-helmet";
import BlocTxtImage from "../../BlocTxtImage.js";


class Partage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      databis: {},
      error: null,
      isLoaded: false
    };
  }

  componentDidMount() {
    fetch("/data/json/data.json")
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            databis: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, databis } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div />;
    } else {
      return (
        <div className="Partage">

          <Helmet>
            <title>Nos Actions Partage  | Fondation Free</title>
            <meta name="description" content="Rassemblés pour innover, Encourager la création, la collaboration et le partage des ressources grâce au logiciel libre" />
          </Helmet>

          <BlocImageIntro data={databis.Partage.BlocImageIntro} />
          <Droite data={databis.Partage.ImageGaucheTextedroite} />
          <Video />
          <BlocTxtImage data={databis.Partage.Projets} />
        
          {/* <Bas data={databis.Partage.Bas} /> */}
         
          <Gauche data={databis.Partage.ImageDroiteTexteGauche} />
          {/* <Chiffres data={databis.Partage.Chiffres}/> */}
          
        </div>
      );
    }
  }
}
export default Partage;
