import React, { Component } from 'react';
import nl2br from 'react-nl2br';


class Chiffres extends Component {
 
    render() {
 
        const divStyle = {
            backgroundImage: 'url(' + this.props.data.src2880x982 + ')',
        };

    return (
    <div><div className="clearboth"/>
    <div className="BlocImageIntro" style={divStyle}>
    </div>
        {
             this.props.data.titre ?  <div className="BlocImageIntroTitre">
                <div className="BlocImageIntroTitre1">{nl2br(this.props.data.titre)}</div>
            </div> : null
        }
       
            {
             this.props.data.titre2 ?  
             <div className="BlocImageIntro2">
                  <div className="BlocImageIntroTitre2">{nl2br(this.props.data.titre2)}</div>
            </div>
              : null
            }
       
   </div>
    );
  }
}

export default Chiffres;