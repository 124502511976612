import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import SideBar from './Sidebar';
 import Slide from 'react-reveal/Slide';





class Header extends Component {
 
    constructor(props) {
        super(props);
        this.state = { show: false };
        this.handleClick = this.handleClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
      }
      handleClick() {
        this.setState({ show: !this.state.show });
      }
    
      setWrapperRef(node) {
        this.wrapperRef = node;
      }
    
      componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.setState({ show: false });

        }
      }

     

    render() {

        return ( 
              <header className="page-header"><SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
              {/* <NavLink to="/Collaborateurs" title="La Fondation" className="nav-link" activeStyle={{fontWeight: "bold"}}><div className="HomePageCollaborateurs">Espace Collaborateurs</div></NavLink> */}
              <div className="burger"></div> 
  <div className="logo"><NavLink to="/"><img src="/assets/img/fondation_free@300x.png"  alt="La Fondation Free"/></NavLink></div>
  <div className="menu">
         <NavLink to="/Fondation" title="La Fondation" className="nav-link" activeStyle={{fontWeight: "bold"}}>Fondation</NavLink><div>|</div>
         <NavLink to="/Actualites" title="Actualités" className="nav-link" activeStyle={{fontWeight: "bold"}}>Actualités</NavLink><div>|</div>
        <div onClick={this.handleClick} className="nav-linkaction" title=" Projets Soutenus "  ref={this.setWrapperRef}><div className="menuactionbloc forcewidth" >Nos Actions</div>
           <Slide top when={this.state.show}>
             <div className="menuaction" title="Projets soutenus" >
              <NavLink to="/Culture" title="Culture" className="nav-link-action" activeStyle={{fontWeight: "bold"}} > Culture </NavLink>
              <NavLink to="/Partage" title="Partage" className="nav-link-action" activeStyle={{fontWeight: "bold"}}> Partage </NavLink>
             
            </div>
          </Slide>
        </div>  
                  
 <div>|</div>
         
<NavLink to="/Votreprojet" title="Votre Projet" className="nav-link" activeStyle={{fontWeight: "bold"}}>Votre Projet</NavLink>



</div> 
  
</header>
            )

        }
}

export default Header;