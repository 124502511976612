import React, { Component } from "react";

class Droite extends Component {
  render() {
    return (
      <div className="HpBlocGris">
      <div className="tailledusitecourt1100 ">

      <h1 className="noborder">{this.props.data.Droitetitre}</h1>
        <div className="Bloc flexcenter">
          <div className="Bloc50TxtGauche VotreProjetGauche">
            <div className="Bloc50TxtGaucheImg">
              <img
                src={this.props.data.Droitesrc707x713}
                alt={this.props.data.Droitetitre}
              />
              <div className="clearboth" />
            </div>
            
            <div className="Bloc50TxtGaucheTxt">
              {/* <h1 className="flex">{nl2br(this.props.data.Droitetitre)}</h1> */}
              <br/>
              <div><strong>{this.props.data.DroiteTitre1}</strong></div>
              <div className="HomePageFull2BlocsChiffres Votreprojettopspace flexspace">
                <div className="content">
                  <div>
                    <img src="/assets/img/check.png" alt="" />
                  </div>
                  <h2>{this.props.data.DroiteTexte1}</h2>
                </div>

                <div className="content">
                  <div>
                    <img src="/assets/img/carte-de-france.png" alt="" />
                  </div>
                  <h2>
                  {this.props.data.DroiteTexte2}
                  </h2>
                </div>

                <div className="content">
                  <div>
                    <img src="/assets/img/premier.png" alt="" />
                  </div>
                  <h2>{this.props.data.DroiteTexte3}</h2>
                </div>
                

              </div>
              <div className="clearboth" />
              <br/>
              {/* <div>{nl2br(this.props.data.Droitetexte)}</div> */}
              <div className="clearboth" />
              <div><div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.Droitetexte
                }}
              />

                </div>
                </div>
              
          </div><div className="clearboth" />
        </div><div className="clearboth" />
      </div>
      </div>
    );
  }
}

export default Droite;
