import 'react-app-polyfill/ie9';

//import ReactGA from 'react-ga';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './css/normalize.css'
import './css/animation.css'
import './css/main.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './css/footer.css'
import './css/header.css'
import './css/new.css'
import './css/carousel.css';
import './css/burger.css'
import './css/responsive.css'
import App from './components/App';
import * as serviceWorker from './serviceWorker';

//ReactGA.initialize('UA-136458538-1');


ReactDOM.render((
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ), document.getElementById('container'));

serviceWorker.unregister();