import React, { Component } from "react";
import axios from 'axios';


const API_PATH = 'https://www.fondation-free.fr/mail/mail.php';
//import nl2br from 'react-nl2br';

class Content extends Component {

  constructor(props) {

    super(props);

    this.state = {
      nom: '',
      prenom: '',
      societe: '',
      mail: '',
      body: '',
      association: '',
      associationmail: '',
      associationsite: 'http://',
      message: '',
      messageErrorNom: '',
      messageErrorPreNom: '',
      messageErrorSociete: '',
      messageErrorMail: '',
      messageErrorbody: '',
      messageErrorassociation: '',
      messageErrorassociationmail: '',
      messageErrorassociationsite: '',
      mailSent: false,
      error: null
    }

  }




  handleFormSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          messageErrorNom: result.data.messageErrorNom,
          messageErrorPreNom: result.data.messageErrorPreNom,
          messageErrorSociete: result.data.messageErrorSociete,
          messageErrorMail:  result.data.messageErrorMail,
          messageErrorbody:  result.data.messageErrorbody,
          messageErrorassociation:  result.data.messageErrorassociation,
          messageErrorassociationmail:  result.data.messageErrorassociationmail,
          messageErrorassociationsite: result.data.messageErrorassociationsite,
          message: result.data.message
        })
        console.log(result.data);

      })
      .catch(error => this.setState({ error: error.message }));
  };



  render() {
    return ( <div>
          <div className="tailledusitecourt1100 Collaborateurs">
                <h1 className="marginbottom">COLLABORATEURS ILIAD</h1>
                <div className="BlocImageIntroTitre2">Merci de compléter cette fiche avant le 31 mai pour nous pemettre d'en savoir plus sur votre projet ! </div>
                </div>
      <div className="tailledusite Contacts">
        <div className="ContactsImage">
          <img src="/data/img/Votreprojet-Gauchedroite-Droitesrc707x713.jpg" alt="Contactez-Nous"></img>
        </div>
        <div className="ContactsTxt">
          <form action="#">
            <div><label>Nom</label>
              <input type="text" id="nom" name="nom" required  placeholder={this.state.messageErrorNom && "Obligatoire"} value={this.state.nom} onChange={e => this.setState({ nom: e.target.value })} />
            </div>


            <div><label>Prénom</label><input type="text" name="prenom" required  placeholder={this.state.messageErrorPreNom && "Obligatoire"}  value={this.state.prenom} onChange={e => this.setState({ prenom: e.target.value })} /></div>
            
            <div><label>Société / Entité</label> <input type="text" name="societe"  required  placeholder={this.state.messageErrorSociete && "Obligatoire"}   value={this.state.societe} onChange={e => this.setState({ societe: e.target.value })} /></div>
            
            <div><label>Adresse Mail Professionnelle</label><input type="email" name="mail"  placeholder={this.state.messageErrorMail && "Obligatoire"}   required value={this.state.mail} onChange={e => this.setState({ mail: e.target.value })} /></div>
            
            <div className="textarealabel"><label className="textarealabel">En quelques lignes parlez-nous du projet et de l’association que vous souhaitez parrainer ( Quand et comment l'avez connue ? Pourquoi vous tient-elle à coeur ? )</label><textarea name="body"  placeholder={this.state.messageErrorbody && "Obligatoire"}   required value={this.state.body} onChange={e => this.setState({ body: e.target.value })} cols="80" rows="10"></textarea></div>

            <div><label>Nom de l'association</label><input type="text" name="association"  placeholder={this.state.messageErrorassociation && "Obligatoire"}   required value={this.state.association} onChange={e => this.setState({ association: e.target.value })} /></div>
            
            <div><label>Mail de l'association</label> <input type="email" name="associationmail"  placeholder={this.state.messageErrorassociationmail && "Obligatoire"}   required value={this.state.associationmail} onChange={e => this.setState({ associationmail: e.target.value })} /></div>
            
            <div><label>Site web de l'association</label> <input type="text" name="associationsite" pattern="https?://.+"  placeholder={this.state.messageErrorassociationsite && "Obligatoire"}   required value={this.state.associationsite} onChange={e => this.setState({ associationsite: e.target.value })} /></div>
            
           
            <div className="formsubmit">
            <input className="buttonred" value="Soumettre projet" type="submit" onClick={e => this.handleFormSubmit(e)} />
            </div>
           
            <span 
                dangerouslySetInnerHTML={{
                  __html:this.state.message
                }}
              />
          </form>
        </div>
        <div className="clearboth" />
        <br />
      </div>
      </div>
    );
  }
}

export default Content;
