import React, { Component } from "react";
//import nl2br from 'react-nl2br';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
        nom: "",
        email:"", 
        sujet: "", 
        body: "" 
    };
    this.nom= this.nom.bind(this);
    this.sujet = this.sujet.bind(this);
    this.body = this.body.bind(this);
    this.email = this.email.bind(this);

    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  sujet(event) {
    this.setState({ sujet: event.target.value });
  }

  body(event) {
    this.setState({ body: event.target.value });
  }

  nom(event) {
    this.setState({ nom: event.target.value });
  }

  email(event) {
    this.setState({ email: event.target.value });
  }

  render() {
    return (
      <div className="tailledusite ContactsFondation">
      <div className="clearboth" /> <div className="ContactsImage">
          <img src="/data/img/Votreprojet-Gauchedroite-Droitesrc707x713.jpg" alt="Contactez-Nous"></img>
        </div>
        <div className="ContactsTxt">
         <div><label>Nom</label><input type="text" name="nom" onChange={this.nom} /></div>
         <div><label>Email</label><input type="text" name="email" onChange={this.email} /></div>
         <div><label>Sujet</label> <input type="text" name="sujet" onChange={this.sujet} /></div>
         <div><label>Votre message</label><textarea name="body" onChange={this.body}></textarea></div>
        
        
         <div><a className="buttonred"
            href={
              "mailto:contact@fondation-free.fr?subject="+
              this.state.sujet +
              "&body=Nom : "+this.state.nom+"%0D%0A %0D%0A Email : "+this.state.email+"%0D%0A %0D%0A  Message%0D%0A "+
              this.state.body.replace(/\n/mg,"%0D%0A")
            }
          >
            Envoyer
          </a></div>
        </div>
        <div className="clearboth" />
        <br />
      </div>
    );
  }
}

export default Content;
